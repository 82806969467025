<template>
  <div>
    <CRow>
      <CCol class="ml-5 mr-5">
        <div class="text-left">
          <CLink to="/dashboard" style="color: #778899;"><i class="fas fa-backward"></i> กลับไปยังก่อนหน้า</CLink> 
        </div>
      </CCol>
      <!-- <CCol class="ml-5 mr-5">
        <div class="text-right">
          <CreateEventModal @afterCreateEvent="callEventListAgain" v-if="showCreateBtn"/>
        </div>
      </CCol> -->
    </CRow>
    

    <div style="display: flex; justify-content: space-between;">
    <!-- <CreateEventModal @afterCreateEvent="callEventListAgain" v-if="showCreateBtn"/> -->
    <div class="pt-2" style="display: inline-block;" >

      <!-- <CDropdown
        v-if="!isAdmin"
        color="outline-customblue2"
        toggler-text="เรียงตาม" 
        style="display: inline-block;"
      >
        <CDropdownItem @click="changeSort(1)"><i class="" v-bind:class="{ 'fas fa-check text-customblue2': sortSelected === 1, 'pl-custom-20': sortSelected !== 1}"></i> <b>วันที่</b> ใหม่สุด <i class="fas fa-arrow-alt-circle-right "></i> เก่าสุด</CDropdownItem>
        <CDropdownItem @click="changeSort(2)"><i class="" v-bind:class="{ 'fas fa-check text-customblue2': sortSelected === 2, 'pl-custom-20': sortSelected !== 2}"></i> <b>วันที่</b> เก่าสุด <i class="fas fa-arrow-alt-circle-right "></i> ใหม่สุด</CDropdownItem>
      </CDropdown> -->
      <CButtonGroup v-if="isAdmin" style="display: inline-block;" class="ml-2">
        <CButton color="outline-primary" v-for="(value, key) in ['Grid', 'Table']" :key="key" class="mx-0" :pressed="value === selected ? true : false" @click="setLocalStorageShowStyle(value)">
          {{ value }}
        </CButton>
      </CButtonGroup>
    </div>
    </div>
    <CRow class="">
      <CCol class="ml-5 mr-5">
         <div class="mt-3">  
          <a style="font-size: 42px; font-weight: bolder; line-height: normal; text-align: center;">
            All Events
          </a>
        </div>
      </CCol>
      <CCol class="ml-5 mr-5" >
        <div  class="w-100 p-4 d-flex align-items-center justify-content-end">
          <CreateEventModal @afterCreateEvent="callEventListAgain" v-if="showCreateBtn"/>
        </div>
      </CCol>
    </CRow>
    <CRow >
      <CCol class="mr-5 ml-5"  align="center" >
        <div class="mt-3">
        </div>
        <CardsEvent2 v-bind:key="event._id" v-for="event in eventList" :eventObject="event" @afterDeleteEvent="callEventListAgain" />
      </CCol>
    </CRow> 
    <CRow v-if="selected === 'Grid'">
      <!-- <CardsEvent v-bind:key="event._id" v-for="event in eventList" :eventObject="event" @afterDeleteEvent="callEventListAgain" /> -->
    </CRow>
    <CCard body-wrapper v-if="selected === 'Table'">
      <CDataTable
        border
        hover
        stripe
        :table-filter="{ placeholder: 'ค้นหา...', label: ' ' }"
        sorter
        :items="eventList"
        :fields="fieldsEventList"
        clickable-rows
        @row-clicked="redirectToEventTable"
        :sorterValue="{ column: 'startAt', asc: false }"
      >
        <template #index="data">
          <td>
            {{ data.index + 1 }}
          </td>
        </template>
        <template #unixtime="data">
          <td>
            {{ timeThaiFormat(data.item.unixtime) }}
          </td>
        </template>
        <template #startAt="data">
          <td>
            {{ timeThaiFormat(data.item.startAt) }}
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
// import WidgetsEvent from "./WidgetsEvent"
// import CardsEvent from "./CardsEvent"
import CardsEvent2 from "./CardsEvent2.vue"
import CreateEventModal from "./CreateEventModal"
import examManangerApi from "@/services/apiServices"
import CryptoJS from 'crypto-js'

export default {
  name: "Eventlist",
  components: {
    // WidgetsEvent,
    // CardsEvent,
    CardsEvent2,
    CreateEventModal,
  },
  data() {
    return {
      selected: "Grid",
      isHovering: false,
      eventList: [],
      adminList: ["nuttapong.pongkam@cmu.ac.th", "thapakorn.pe@cmu.ac.th"],
      bgColor: "#778899",
      position: "bottom-right",
      fabActions: [
        {
          name: "cache",
          icon: "cached",
        },
        {
          name: "alertMe",
          icon: "comment",
        },
      ],
      fieldsEventList: [
        { key: "index", label: "No." },
        { key: "eventName", label: "ชื่อ Event" },
        { key: "owner", label: "เจ้าของ" },
        { key: "unixtime", label: "วันที่สร้าง" },
        { key: "startAt", label: "เริ่มสอบ" },
        { key: "endAt", label: "สิ้นสุด" },
      ],
      isAdmin: false,
      showCreateBtn: true,
      sortSelected: 1
    }
  },
  created() {
    let role = CryptoJS.AES.decrypt(localStorage.getItem("Admin"), process.env.VUE_APP_LOCALSTORAGE_KEY).toString(CryptoJS.enc.Utf8)
    if (role === "super-admin") {
      if (this.$route.query.geteventof) {
        this.isAdmin = true
        if (this.$route.query.geteventof == "all") {
          this.getEventListALl()
        } else {
          this.getEventListByOwner(this.$route.query.geteventof)
        }
      } else {
        console.log("Get Event: Get Event of yourself")
        this.getEventListByOwner(localStorage.getItem("email"))
      }
    } else {
      this.getEventListByOwner(localStorage.getItem("email"))
    }
  },
  methods: {
    setLocalStorageShowStyle(style) {
      localStorage.setItem("tablegrid", style)
      this.selected = style
    },
    async getEventListByOwner(email) {
      let eventList = await examManangerApi.getCollaboratorListByUser(email)
      if (eventList["status"]) {
        this.eventList = []
        eventList["data"].sort(function(a, b) {
          let keyA = new Date(a.updatedAt),
          keyB = new Date(b.updatedAt)
          if (keyA < keyB) return 1
          if (keyA > keyB) return -1
          return 0
        })
        for (let event of eventList["data"]) {
          let tmp = await examManangerApi.getEventById(event.eventid)
          console.log(event._id,"detail :>" , tmp)
          event["startAt"] = tmp.startAt
          this.eventList.push(event)
        }

      }
    },
    async getEventNameById(eventId) {
      let event = await examManangerApi.getEventById(eventId)
      if (event) {
        return event.eventName
      }
      return "Event Name Not Found"
    },
    async getEventListALl() {
      let eventList = await examManangerApi.getEventListAll()
      if (eventList["status"]) {
        this.eventList = eventList["data"]
        console.log("eventListAll:", eventList)
        this.selected = localStorage.getItem("tablegrid") ? localStorage.getItem("tablegrid") : "Grid"
      }
    },
    btnClicked(index) {
      this.$router.push({ path: `events/${index}` })
    },
    callEventListAgain(value) {
      console.log(value)
      this.getEventListByOwner(localStorage.getItem("email"))
    },
    cache() {
      console.log("Cache Cleared")
    },
    alert() {
      alert("Clicked on alert icon")
    },
    redirectToEventTable(eventOnClick) {
      window.open(`events/${eventOnClick["_id"]}/detail`, "_blank").focus()
    },
    timeThaiFormat(timestamp) {
      const option = { year: "numeric", month: "long", day: "numeric", weekday: "long", hour: "numeric", minute: "numeric", second: "numeric" }
      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", option)
    },
    async changeSort(selected){
      console.log(selected)
      this.sortSelected = selected
      let eventList = this.eventList
      this.eventList = []
      switch (selected) {
        case 1:
          eventList.sort(function(a, b) {
            let keyA = new Date(a.updatedAt),
            keyB = new Date(b.updatedAt)
            if (keyA < keyB) return 1
            if (keyA > keyB) return -1
            return 0
          })
          for (let event of eventList) {
            let tmp = await examManangerApi.getEventById(event.eventid)
            console.log(event._id,"detail :>" , tmp)
            event["startAt"] = tmp.startAt
            this.eventList.push(event)
          }
          break;
        case 2:
          eventList.sort(function(a, b) {
            let keyA = new Date(a.updatedAt),
            keyB = new Date(b.updatedAt)
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
          })
          for (let event of eventList) {
            let tmp = await examManangerApi.getEventById(event.eventid)
            console.log(event._id,"detail :>" , tmp)
            event["startAt"] = tmp.startAt
            this.eventList.push(event)
          }
          break;
      
        default:
          break;
      }
    }
  },
}
</script>
