<template>
  <div style="display:inline-block">
    <div style="bottom: 0;">
       <CButton size="lg" color="pastelpurple2" class="text-white"  @click="createEventModal = true"  style="width: 100%; bottom: 0;">
          <i class="fas fa-plus"></i>
      Create Events
  </CButton>
      <!-- <CButton @click="createEventModal = true" color="warning">Create New</CButton> -->
    </div>
    <CModal title="Create Event" :show.sync="createEventModal" :centered="true" size="lg" :closeOnBackdrop="false">
      <CForm novalidate id="create-event-form">
        <div>
          <md-steppers :md-active-step.sync="createFormStepper.active"  md-vertical md-linear>
            <md-step color="pastelpurple2" id="eventName" md-label="EVENT NAME - ตั้งชื่อการสอบ" :md-editable="false" :md-done.sync="createFormStepper.eventName">
              <CInput
                id="fromEventName"
                type="text"
                description="ชื่อหัวข้อที่ใช้การสอบ เช่น Test 259567 - Final Porject [2/63]"
                prepend="Name"
                required
                invalid-feedback="Please provide at least 4 characters."
                :is-valid="eventNameValidator"
                :max-size="5"
              />
              <CButton @click="setDone('eventName', 'lmsType')" color="pastelpurple2">CONTINUE</CButton>
            </md-step>
            <md-step id="lmsType" md-label="LMS/SERVICE - เลือก LMS ที่ใช้สอบ" :md-editable="false" :md-done.sync="createFormStepper.lmsType">
              <div class="pb-3">
                <CRow>
                  <CCol sm="9" xl="3">
                    <center>
                      <CButton @click="setDone('lmsType', 'lmsCourse', 'cmuexam')" size="lg" color="outline-pastelpurple2" style="width: 150px;height:150px">
                        <img src="img/TLIC/cmu-exam-logo-edit-200.png" height="100" />
                      </CButton>
                    </center>
                  </CCol>
                  <!-- <CCol sm="9" xl="3">
                    <center>
                      <CButton @click="setDone('lmsType', 'lmsCourse', 'cmuonline')" size="lg" color="outline-pastelpurple2" style="width: 150px;height:150px">
                        <img src="img/TLIC/cmu-online-logo-edit-200.png" height="100" />
                      </CButton>
                    </center>
                  </CCol> -->
                  <!-- <CCol sm="9" xl="3">
                    <center>
                      <CButton @click="setDone('lmsType', 'lmsCourse', 'cmucanvas')" size="lg" color="outline-pastelpurple2" style="width: 150px;height:150px">
                        <img src="img/TLIC/canvas-cmu-logo-edit-200.png" height="100" />
                      </CButton>
                    </center>
                  </CCol> -->
                  <CCol sm="9" xl="3">
                    <center>
                      <CButton @click="setDone('lmsType', 'lmsCourse', 'cmumango')" size="lg" color="outline-pastelpurple2" style="width: 150px;height:150px">
                        <img src="img/TLIC/mango-cmu-logo-edit-200.png" height="100" />
                      </CButton>
                    </center>
                  </CCol>
                </CRow>
              </div>
              <CButton @click="setUndone('lmsType', 'eventName')" color="md-accent">BACK</CButton>{{ " " }}
            </md-step>

            <md-step id="lmsCourse" md-label="CHOOSE COURSE FROM LMS - เลือกวิชาที่จะใช้สอบ" :md-editable="false" :md-done.sync="createFormStepper.lmsCourse">
              <CRow>
                <CCol>
                  <div v-show="loadingLmsList" class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </CCol>
              </CRow>
              <CRow >
                <CCol v-if="lmsListArr.length > 1">
                  <CCollapse :show="!loadingLmsList">
                    <CSelect :options="lmsListArr" placeholder="select course" @update:value="onSelectLmsCourse" />
                  </CCollapse>
                </CCol>
                <CCol v-else>
                  <CCol sm="12" v-show="!loadingLmsList">
                    <CCard body-wrapper >
                      <CRow>
                        <CCol sm="12">
                          <h5><CIcon :height="50" name="cil-x" />ไม่พบวิชา</h5>
                        </CCol>
                      </CRow>
                    </CCard>
                  </CCol>
                </CCol>
              </CRow>
              <CButton @click="setUndone('lmsCourse', 'lmsType')" color="md-accent">BACK</CButton>{{ " " }}
              <CButton v-show="!loadingLmsList" @click="setDone('lmsCourse', 'quizAssignment')" color="pastelpurple2">CONTINUE</CButton>
            </md-step>

            <md-step id="quizAssignment" md-label="CHOOSE QUIZ/ASSIGNMENT - เลือก Quiz/Assignment ที่จะติดตาม" :md-editable="false" :md-done.sync="createFormStepper.quizAssignment">
              <CRow>
                <CCol>
                  <div v-show="loadingQuiz" class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </CCol>
              </CRow>
              <div v-show="!loadingQuiz">
                <CCollapse :show="cardCollapseQuiz">
                  <CCard body-wrapper>
                    <div v-if="haveAQuizAtCreateModal">
                      <span><b>Quiz</b></span>
                      <CRow>
                        <CCol sm="12">
                          <CInputCheckbox label="เลือกทั้งหมด" @update:checked="checkAllQuizFunc" :checked="checkAllQuiz" value="CheckAkk" />
                          <CInputCheckbox v-bind:key="quiz.id" v-for="quiz of quizList" :label="quiz.name" @update:checked="checkSomeQuiz" :checked="checkAllQuiz" :value="quiz.id + ':' + quiz.name" />
                        </CCol>
                      </CRow>
                    </div>
                    <div v-if="haveAAssignAtCreateModal">
                      <span><b>Assignment</b></span>
                      <CRow>
                        <CCol sm="12">
                          <CInputCheckbox label="เลือกทั้งหมด" @update:checked="checkAllAssignmentFunc" :checked="checkAllAssignment" value="CheckAkk" />
                          <CInputCheckbox
                            v-bind:key="assignment.id"
                            v-for="assignment of assignmentList"
                            :label="assignment.name"
                            @update:checked="checkSomeAssignment"
                            :checked="checkAllAssignment"
                            :value="assignment.id + ':' + assignment.name"
                          />
                        </CCol>
                      </CRow>
                    </div>
                  </CCard>
                </CCollapse>
                <CCollapse :show="cardCollapseQuizNotSelected">
                  <CCol sm="12">
                    <CCard body-wrapper class="text-danger">
                      <CRow>
                        <CCol sm="12">
                          <h6><CIcon :height="25" name="cil-warning" />Quiz, Assignment ยังไม่ถูกเลือก</h6>
                        </CCol>
                      </CRow>
                    </CCard>
                  </CCol>
                </CCollapse>
                <CCollapse :show="cardCollapseQuizNotFound">
                  <CCol sm="12">
                    <CCard body-wrapper>
                      <CRow>
                        <CCol sm="12">
                          <h5><CIcon :height="50" name="cil-x" />ไม่พบ Quiz หรือ Assignment</h5>
                        </CCol>
                      </CRow>
                    </CCard>
                  </CCol>
                </CCollapse>
              </div>
              <CButton @click="setUndone('quizAssignment', 'lmsCourse')" color="md-accent">BACK</CButton>{{ " " }}
              <CButton v-show="cardCollapseQuiz" @click="setDone('quizAssignment', 'studentGroups')" color="md-primary">CONTINUE</CButton>
            </md-step>
            <md-step id="studentGroups" md-label="CHOOSE STUDENT GROUPS (OPTIONAL) - เลือกกลุ่มนักศึกษา (ใช้เมื่อต้องการ)" :md-editable="false" :md-done.sync="createFormStepper.studentGroups">
              <CRow>
                <CCol>
                  <div v-show="loadingStudentGroups" class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </CCol>
              </CRow>
              <div v-show="!loadingStudentGroups">
                <CCollapse :show="studentGroups.listForShow.length > 0">
                  <CRow>
                    <CCol sm="1">
                      <CSwitch class="mr-1" color="primary" shape="pill" @update:checked="useStudentGroup = $event" />
                    </CCol>
                    <CCol sm="8">
                      <h6 class="pt-1">
                        เลือกกลุ่มนักศึกษาแบบเจาะจง <a @click="showUseStudentGroupDetail"><i class="far fa-question-circle"></i></a>
                      </h6>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <p class="text-dark">
                        <small> หากไม่เปิดใช้งานเลือกกลุ่มนักศึกษาแบบเจาะจง ระบบจะทำการ<b class="text-danger">นำเข้านักศึกษาทั้งหมดในคอร์ส</b> </small>
                      </p>
                    </CCol>
                  </CRow>
                  <CCollapse :show="useStudentGroup">
                    <CCard body-wrapper>
                      <CRow v-if="studentGroups.listForShow.length > 0">
                        <CCol sm="12">
                          <CInputCheckbox label="เลือกทั้งหมด" @update:checked="checkAllGroupFunc" :checked="checkAllGroup" value="CheckAkk" />
                          <CInputCheckbox
                            v-bind:key="group.id"
                            v-for="group of studentGroups.listForShow"
                            :label="`${group.name} (จำนวนนักศึกษา ${group.length} คน)`"
                            @update:checked="checkSomeGroup"
                            :checked="checkAllGroup"
                            :value="group.id"
                          />
                        </CCol>
                      </CRow>
                    </CCard>
                  </CCollapse>
                </CCollapse>
                <CCollapse :show="!(studentGroups.listForShow.length > 0)">
                  <CRow>
                    <CCol sm="8">
                      <p><i class="fas fa-times"></i> Student Group Not found</p>
                    </CCol>
                  </CRow>
                </CCollapse>
              </div>
              <CButton @click="setUndone('studentGroups', 'quizAssignment')" color="md-accent">BACK</CButton>{{ " " }}
              <CButton @click="setDone('studentGroups', 'studentLocation')" color="md-primary">CONTINUE</CButton>
            </md-step>
            <md-step id="studentLocation" md-label="STUDENTS LOCATION - การบันทึกพิกัดผู้สอบ" :md-editable="false" :md-done.sync="createFormStepper.studentLocation">
              <CRow>
                <CCol sm="1">
                  <CSwitch class="mr-1" color="primary" shape="pill" @update:checked="useLocation" />
                </CCol>
                <CCol sm="8">
                  <h6 class="pt-1"><i class="fas fa-map-marker-alt"></i> เปิดใช้การบันทึกพิกัดของผู้สอบ</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p>
                    <small class="text-danger">
                      ***ต้องมีการให้สิทธิ์การเข้าถึงตำแหน่งในฝั่งของนักศึกษา นักศึกษาถึงจะสามารถ Check-in***
                    </small><br>
                    <small class="text-dark">
                      นักศึกษาควรใช้สมาร์ทโฟน หรือ อุปกรณ์ที่มี GPS เพื่อความแม่นยำของพิกัดทีได้
                    </small>
                  </p>
                </CCol>
              </CRow>
              <CButton @click="setUndone('studentLocation', 'studentGroups')" color="md-accent">BACK</CButton>{{ " " }}
              <CButton @click="setDone('studentLocation', 'zoom')" color="md-primary">CONTINUE</CButton>
            </md-step>
            <md-step id="zoom" md-label="ZOOM - ตั้งค่าการคุมสอบผ่าน Zoom" :md-editable="false" :md-done.sync="createFormStepper.zoom">
              <CRow>
                <CCol sm="1">
                  <CSwitch  class="mr-1" color="primary" shape="pill" @update:checked="useZoom" />
                </CCol>
                <!-- <CCol sm="8">
                  <h6 class="pt-1">ใช้ Zoom meeting ในการคุมสอบ</h6> <small class="text-danger">
                      (ไม่สามารถใช้งานได้ชั่วคราว)
                    </small>
                </CCol> -->
              </CRow>
              <CRow>
                <CCol>
                  <p class="text-dark">
                    <small>
                      ระบบจะติดตามการเข้าใช้ Zoom ได้เมื่อท่านเปิดใช้ตัวเลือกบังคับให้ผู้เข้าร่วมประชุม login ด้วย CMU Account [
                      <a target="blank" href="https://docs.tlic.cmu.ac.th/online-exam-manager-documentation/zoom/login-cmu-account">อ่านคู่มือ</a> ]
                    </small>
                  </p>
                </CCol>
              </CRow>
              <CCollapse :show="showZoomInput">
                <CRow>
                  <CCol sm="8">
                    <CInput
                      type="url"
                      prepend="Zoom Link"
                      description="เช่น https://cmu-th.zoom.us/j/9445095480"
                      required
                      :invalid-feedback="zoomValidateMessage"
                      :is-valid="zoomIdConfigCMU"
                      :value="eventZoomUrl"
                      @update:value="eventZoomUrlValidator"
                    />
                  </CCol>
                  <CCol class="text-left pt-1" v-show="onLoadCheckZoomConfig">
                    <i class="fas fa-spinner fa-pulse fa-2x"></i>
                  </CCol>
                </CRow>
              </CCollapse>
              <CButton @click="setUndone('zoom', 'studentLocation')" color="md-accent">BACK</CButton>{{ " " }}
              <div v-if="!showZoomInput" style="display: inline-block">
                <CButton @click="setDone('zoom', 'dateTime')" color="md-primary">CONTINUE</CButton>
              </div>
              <div v-else style="display: inline-block">
                <CButton v-show="zoomIdConfigCMU" @click="setDone('zoom', 'dateTime')" color="md-primary">CONTINUE</CButton>
              </div>
            </md-step>
            <md-step id="dateTime" md-label="EXAM DATE/TIME - วันและเวลาสอบ" :md-editable="false" :md-done.sync="createFormStepper.dateTime">
              <CRow>
                <CCol sm="4">
                  <b-input-group class="mb-2">
                    <b-form-datepicker @input="dateValidator" locale="th" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }" placeholder="--">
                    </b-form-datepicker>
                  </b-input-group>
                </CCol>
                <CCol sm="4" class="pb-2">
                  <CButton color="secondary" disabled style="cursor: context-menu;color: #000">เริ่ม</CButton>{{ " " }}
                  <vue-timepicker input-class="form-control py-3 bg-can-edit" @input="startTimePickerHandle" :minute-interval="5"></vue-timepicker>
                </CCol>
                <CCol sm="4" class="pb-2">
                  <CButton color="secondary" disabled style="cursor: context-menu;color: #000">สิ้นสุด</CButton>{{ " " }}
                  <vue-timepicker input-class="form-control py-3 bg-can-edit" @input="endTimePickerHandle" :minute-interval="5" v-model="vModelForEndTime"></vue-timepicker>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <p class="text-dark">
                    <small>
                      นักศึกษาจะสามารถ Check-in ได้ 30 นาทีก่อนเวลาที่ระบุนี้ (แก้ไขได้ภายหลังจาก settings)
                    </small>
                  </p>
                </CCol>
              </CRow>
              <CButton @click="setUndone('dateTime', 'zoom')" color="md-accent">BACK</CButton>{{ " " }}
              <CButton @click="setDone('dateTime', 'contact')" color="md-primary">CONTINUE</CButton>
            </md-step>
            <md-step id="contact" md-label="EMERGENCY CONTACT FOR STUDENT - ข้อมูลติดต่อสำหรับนักศึกษาในกรณีฉุกเฉิน" :md-editable="false" :md-done.sync="createFormStepper.contact">
              <CRow>
                <CCol sm="4">
                  <CInput type="email" prepend="Email" @change="emailInput" />
                </CCol>
                <CCol sm="4">
                  <CInput type="number" prepend="Phone" @change="phoneInput" />
                </CCol>
                <CCol sm="4">
                  <CInput type="text" prepend="Other" @change="otherInput" />
                </CCol>
              </CRow>
              <CButton @click="setUndone('contact', 'dateTime')" color="md-accent">BACK</CButton>{{ " " }}
              <CButton @click="submitCreate" color="primary"><i class="fas fa-check-circle"></i> SUBMIT</CButton>
            </md-step>
          </md-steppers>
        </div>
      </CForm>
      <CAlert color="warning" closeButton :show.sync="alert1">
        {{ alerttext }}
      </CAlert>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import apiv2 from "@/services/apiServices"
import Swal from "sweetalert2"
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue"

export default {
  name: "CreateEventModal",
  data() {
    // const muiTheme = getMuiTheme({
    //     stepper: {
    //         iconColor: 'green' // or logic to change color
    //     }
    // })
    return {
      muiTheme: "",
      eventName: "",
      eventLmsType: "",
      eventTerm: "",
      eventTermSemester: "",
      eventTermYear: 0,
      eventCourseCode: "",
      eventCourseSection: [],
      eventZoomUrl: "",
      eventExamUrl: "",
      eventExamUrlSelected: "",
      eventLmsSelected: "",
      createEventModal: false,
      message: "test",
      cardCollapseSection: false,
      collapse: false,
      cardCollapseSectionNotFound: false,
      cardCollapseSectionNotSelected: false,
      cardCollapseQuizNotSelected: true,
      cardCollapseQuiz: false,
      haveAQuizAtCreateModal: false,
      haveAAssignAtCreateModal: false,
      cardCollapseQuizNotFound: false,
      cardCollapseAssignment: false,
      cardCollapseAssignmentNotFound: false,
      checkAllSection: false,
      checkAllQuiz: false,
      checkAllAssignment: false,
      loading: false,
      loadingLmsList: true,
      loadingQuiz: false,
      loadingAssignment: false,
      loadingStudentGroups: false,
      lmsListArr: [{ value: "ไม่พบข้อมูลบน Exam", disabled: true }],
      sectionCheckedVal: [],
      quizCheckedVal: [],
      assignmentCheckedVal: [],
      alerttext: "คุณยังไม่ได้กรอก",
      alert1: false,
      quizList: [],
      assignmentList: [],
      examDateTime: {
        date: "",
        startTime: "",
        endTime: "",
      },
      contact: {
        email: "",
        phone: "",
        other: "",
      },
      bootstrapValidate: {
        date: false,
        startTime: false,
        endTime: false,
      },
      datePickerValidate: false,
      showZoomInput: false,
      showform: {
        eventName: false,
        reg: false,
        exam: false,
        zoom: false,
        dateTime: false,
        contact: false,
      },
      checkExamUrl: true,
      examList: [{ value: "ไม่พบข้อมูลบน Exam", disabled: true }],
      examSelectedPlaceholder: "เลือก Exam course",
      examSelectedValue: null,
      testIsNaN: "",
      showCsnOrCu: true,
      studentGroups: {
        listForShow: [],
        listForSend: [],
      },
      createFormStepper: {
        eventName: false,
        lmsType: false,
        lmsCourse: false,
        quizAssignment: false,
        studentGroups: false,
        studentLocation: false,
        zoom: false,
        dateTime: false,
        contact: false,
        active: "eventName",
      },
      
      checkAllGroup: false,
      useStudentLocation: false,
      vModelForEndTime: "",
      useStudentGroup: false,
      zoomIdConfigCMU: true,
      onLoadCheckZoomConfig: false,
      zoomValidateMessage: "รูปแบบ URL ไม่ถูกต้อง",
    }
  },
  components: {
    VueTimepicker,
  },
  created() {
    if (this.$route.query.opencreate === 'true') {
      this.createEventModal = true
    }
  },
  methods: {
    setDone (id, index, val) {
      let validateisPass = false
      switch (id) {
        case "eventName":
          if (this.eventName) {
            validateisPass = true
          }
          break
        case "lmsType":
          this.eventLmsType = val
          this.eventLmsSelected = ""
          this.getLmsOfUser(this.eventLmsType)
          validateisPass = true
          break
        case "lmsCourse":
          if (this.eventLmsSelected) {
            validateisPass = true
            this.searchQuizAndAssignment()
          }
          break
        case "quizAssignment":
          validateisPass = this.quizCheckedVal.length > 0 || this.assignmentCheckedVal.length > 0 ? true : false
          break
        case "studentGroups":
          validateisPass = this.useStudentGroup ? (this.studentGroups.listForSend.length > 0 ? true : false) : true
          break
        case "studentLocation":
          validateisPass = true
          break
        case "zoom":
          if (this.showZoomInput) {
            if (this.eventZoomUrl !== "" && this.zoomIdConfigCMU) {
              validateisPass = true
            }
          } else {
            validateisPass = true
          }
          break
        case "dateTime":
          validateisPass = this.examDateTime.date && this.examDateTime.startTime && this.examDateTime.endTime ? true : false
          break
        default:
          break
      }
      if (validateisPass) {
        this.createFormStepper[id] = true
        if (index) {
          this.createFormStepper.active = index
        }
      }
    },
    setUndone(id, index) {
      this.createFormStepper[id] = false
      if (index) {
        this.createFormStepper.active = index
      }
    },
    showHelpCourseShortName() {
      Swal.fire({
        text: "สามารถดูหรือตั้งค่า Course short name ของกระบวนวิชาบน EXAM CMU ได้ที่ตั้งค่า/Edit settingsของกระบวนวิชานั้นๆ",
        imageUrl: "img/others/help-course-short-name.jpg",
        showConfirmButton: false,
      })
    },
    clickLmsTypeRadio(val) {
      if (val) {
        this.eventLmsType = val
        this.eventLmsSelected = ""
      }
    },
    inputChange(event) {
      this.message = event
    },
    eventNameValidator(val) {
      this.eventName = val
      if (val) {
        if (val.length >= 4) {
          this.showform.reg = true
        } else {
          this.showform.reg = false
        }
      }
      return val ? val.length >= 4 : false
    },
    eventTermValidator(val) {
      let valSplit = val.split("/")
      if (valSplit.length === 2 && val.length == 4) {
        this.eventTerm = `${valSplit[0]}${valSplit[1]}`
        this.eventTermSemester = valSplit[0]
        this.eventTermYear = parseInt(valSplit[1]) + 1957
        return true
      } else {
        return false
      }
    },
    eventCourseCodeValidator(val) {
      this.eventCourseCode = val
      return val ? true : false
    },
    async getLmsOfUser(lmsType) {
      this.loadingLmsList = true
      this.lmsListArr = [{ value: "ไม่พบข้อมูลบน Exam", disabled: true }]
      const resLmsList = await apiv2.getLmsListOfUser(lmsType, localStorage.getItem("email"))
      console.log("check api :> " , resLmsList)
      if (resLmsList.status) {
        this.lmsListArr = [{ value: null, label: "โปรดเลือก...", disabled: true }]
        this.loadingLmsList = false
        for (let lms of resLmsList.data) {
          this.lmsListArr.push({ value: lms.id, label: `${this.lmsListArr.length}. ${lms.fullname}` })
        }
      }
    },
    async onSelectLmsCourse(val) {
      if (val) {
        this.eventLmsSelected = val
        this.cardCollapseQuiz = false
        this.cardCollapseQuizNotSelected = true
        this.showform.exam = true
      }
    },
    async eventZoomUrlValidator(val) {
      if (val !== undefined) {
        let checkStr = val.includes("https://cmu-th.zoom.us/j/")
        let checkStartWith = val.startsWith("https://", 0)
        if (checkStr && checkStartWith) {
          let zoomIdArr = val.split("/")
          let zoomId = zoomIdArr[zoomIdArr.length - 1]
          this.onLoadCheckZoomConfig = true
          const checkConfig = await apiv2.checkZoomCMUConfig(zoomId)
          this.onLoadCheckZoomConfig = false
          if (checkConfig["config"]) {
            this.eventZoomUrl = val
            this.zoomIdConfigCMU = true
          } else {
            this.zoomValidateMessage = "กรุณาตั้งค่าใช้ตัวเลือกบังคับให้ผู้เข้าร่วมประชุม login ด้วย CMU Account"
            this.zoomIdConfigCMU = false
          }
        } else {
          this.eventZoomUrl = ""
          this.zoomValidateMessage = "รูปแบบ URL ไม่ถูกต้อง"
          this.zoomIdConfigCMU = false
        }
      } else {
        this.eventZoomUrl = ""
        this.zoomValidateMessage = "รูปแบบ URL ไม่ถูกต้อง"
        this.zoomIdConfigCMU = false
      }
    },
    async zoomConfigCheck(zoomId) {
      const checkConfig = await apiv2.checkZoomCMUConfig(zoomId)
      return checkConfig
    },
    async eventExamSelectValidator(val) {
      if (val !== undefined) {
        this.eventExamUrlSelected = `https://exam.cmu.ac.th/course/view.php?id=${val}`
        await this.eventExamUrlValidator(this.eventExamUrlSelected)
        this.searchQuizAndAssignment()
        return true
      } else {
        return false
      }
    },
    eventExamUrlValidator(val) {
      if (val !== undefined) {
        let checkStr = val.includes("https://exam.cmu.ac.th/course/view.php?id=")
        this.eventExamUrl = val
        return checkStr
      } else {
        return false
      }
    },
    async searchExamCourse(courseCode) {
      if (courseCode !== "" && courseCode !== undefined) {
        this.showform.exam = false
        this.showform.zoom = false
        this.showform.dateTime = false
        this.showform.contact = false
        this.eventExamUrl = ""
        this.cardCollapseQuizNotFound = false
        this.examList = [{ value: "ไม่พบข้อมูลบน Exam", disabled: true }]
        let examList = await apiv2.getExamCourseByCourseCode(courseCode)
        if (examList && examList.status) {
          if (examList.data.length > 0) {
            this.examList = []
            this.examList = [{ value: null, label: "โปรดเลือก...", disabled: true }]
            for (let exam of examList.data) {
              this.examList.push({ value: exam.courseid, label: exam.displayname })
            }
            this.eventExamUrl = `https://exam.cmu.ac.th/course/view.php?id=${examList.data[0].courseid}`
          } else {
            console.log("No Exam")
            this.eventExamUrl = ""
            this.studentGroups.listForShow = []
            this.studentGroups.listForSend = []
            Swal.fire({
              title: "ไม่พบคอร์สบน CMU EXAM!!!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ไปที่ CMU EXAM",
              cancelButtonText: "ดำเนินการต่อ",
            }).then((result) => {
              if (result.isConfirmed) {
                window.open("https://exam.cmu.ac.th/")
              }
            })
          }
          this.cardCollapseQuiz = false
          this.cardCollapseQuizNotSelected = true
          this.showform.exam = true
          this.searchQuizAndAssignment()
        }
      }
    },
    async searchSection() {
      this.showform.exam = false
      this.checkAllSection = false
      this.eventExamUrl = ""
      this.examList = [{ value: "ไม่พบข้อมูลบน Exam", disabled: true }]
      await this.checkAllSectionFunc(true)
      await this.checkAllSectionFunc(false)
      this.sectionCheckedVal = []
      this.eventExamUrl = ""
      if (this.eventTerm && this.eventCourseCode) {
        this.cardCollapseSectionNotFound = false
        this.cardCollapseSection = false
        this.cardCollapseSectionNotSelected = false
        this.loading = true
        let sectionList = await apiv2.getSection(this.eventCourseCode, "", this.eventTerm)
        this.loading = false
        if (sectionList.status === true) {
          this.cardCollapseSectionNotFound = false
          this.cardCollapseSection = true
          let ordered = Object.keys(sectionList.sections)
            .sort()
            .reduce((obj, key) => {
              obj[key] = sectionList.sections[key]
              return obj
            }, {})
          this.eventCourseSection = ordered
          let newob = _.orderBy(sectionList.sections, "name")
        } else {
          this.cardCollapseSection = false
          this.cardCollapseSectionNotFound = true
        }
        await this.searchExamCourse(this.eventCourseCode)
      }
    },
    async searchQuizAndAssignment() {
      if (this.eventLmsSelected) {
        let examId = this.eventLmsSelected
        let lmsType = this.eventLmsType
        this.searchStudentGroup(examId)
        if (examId) {
          this.quizCheckedVal = []
          this.checkAllQuiz = false
          this.checkAllAssignment = false
          this.cardCollapseQuizNotSelected = false
          this.loadingQuiz = true
          let quizList = await apiv2.getQuiz(lmsType, examId)
          let assignmentList = await apiv2.getAssignment(lmsType, examId)
          this.loadingQuiz = false
          try {
            this.quizList = quizList["data"]
            this.assignmentList = assignmentList["data"]
            this.haveAQuizAtCreateModal = this.quizList.length > 0 ? true : false
            this.haveAAssignAtCreateModal = this.assignmentList.length > 0 ? true : false
            this.cardCollapseQuiz = this.quizList.length !== 0 || this.assignmentList.length !== 0 ? true : false
            this.cardCollapseQuizNotFound = !this.cardCollapseQuiz
          } catch (error) {
            this.cardCollapseQuizNotFound = true
            this.cardCollapseQuiz = false
          }
        }
      }
    },
    async searchStudentGroup(courseid) {
      this.studentGroups.listForShow = []
      this.studentGroups.listForSend = []
      this.loadingStudentGroups = true
      const resStudentGroup = await apiv2.getExamStudentGroup(this.eventLmsType, courseid)
      this.loadingStudentGroups = false
      if (resStudentGroup.status) {
        for (let i = 0; i < resStudentGroup.data.length; i++) {
          if (resStudentGroup.data[i].length > 0) {
            this.studentGroups.listForShow.push(resStudentGroup.data[i])
          }
        }
      }
    },
    checkAllGroupFunc(event, val) {
      this.checkAllGroup = event
      if (event) {
        for (let group of this.studentGroups.listForShow) {
          let checkList = group.id.toString()
          if (!this.studentGroups.listForSend.includes(checkList)) {
            this.studentGroups.listForSend.push(checkList)
          }
        }
      } else {
        for (let group of this.studentGroups.listForShow) {
          let checkList = group.id.toString()
          if (this.studentGroups.listForSend.includes(checkList)) {
            let index = this.studentGroups.listForSend.indexOf(checkList)
            if (index > -1) {
              this.studentGroups.listForSend.splice(index, 1)
            }
          }
        }
      }
    },
    checkSomeGroup(event, val) {
      if (event) {
        let checkList = val.target.value
        if (!this.studentGroups.listForSend.includes(checkList)) {
          this.studentGroups.listForSend.push(checkList)
        }
      } else {
        let checkList = val.target.value
        if (this.studentGroups.listForSend.includes(checkList)) {
          let index = this.studentGroups.listForSend.indexOf(checkList)
          if (index > -1) {
            this.studentGroups.listForSend.splice(index, 1)
          }
        }
      }
    },
    checkAllSectionFunc(event, val) {
      this.checkAllSection = event
      if (event) {
        for (let section in this.eventCourseSection) {
          let checkList = `${this.eventCourseSection[section].type}:${section}`
          if (!this.sectionCheckedVal.includes(checkList)) {
            this.sectionCheckedVal.push(checkList)
          }
        }
      } else {
        for (let section in this.eventCourseSection) {
          let checkList = `${this.eventCourseSection[section].type}:${section}`
          if (this.sectionCheckedVal.includes(checkList)) {
            let index = this.sectionCheckedVal.indexOf(checkList)
            if (index > -1) {
              this.sectionCheckedVal.splice(index, 1)
            }
          }
        }
      }
      if (this.sectionCheckedVal.length === 0) {
        this.showform.exam = false
        this.eventExamUrl = ""
      } else {
        this.showform.exam = true
      }
    },
    checkSomeSection(event, val) {
      if (event) {
        let checkList = val.target.value
        if (!this.sectionCheckedVal.includes(checkList)) {
          this.sectionCheckedVal.push(checkList)
        }
      } else {
        let checkList = val.target.value
        if (this.sectionCheckedVal.includes(checkList)) {
          let index = this.sectionCheckedVal.indexOf(checkList)
          if (index > -1) {
            this.sectionCheckedVal.splice(index, 1)
          }
        }
      }
      if (this.sectionCheckedVal.length === 0) {
        this.showform.exam = false
        this.eventExamUrl = ""
      } else {
        this.showform.exam = true
      }
    },
    checkAllQuizFunc(event, val) {
      this.checkAllQuiz = event
      if (event) {
        for (let quiz of this.quizList) {
          let checkList = (quiz.id + ":" + quiz.name).toString()
          if (!this.quizCheckedVal.includes(checkList)) {
            this.quizCheckedVal.push(checkList)
          }
        }
      } else {
        for (let quiz of this.quizList) {
          let checkList = (quiz.id + ":" + quiz.name).toString()
          if (this.quizCheckedVal.includes(checkList)) {
            let index = this.quizCheckedVal.indexOf(checkList)
            if (index > -1) {
              this.quizCheckedVal.splice(index, 1)
            }
          }
        }
      }
      if (this.quizCheckedVal.length === 0) {
        this.showform.zoom = false
        this.showform.dateTime = false
        this.showform.contact = false
      } else {
        this.showform.zoom = true
        this.showform.dateTime = true
        this.showform.contact = true
      }
    },
    checkSomeQuiz(event, val) {
      if (event) {
        console.log(event, val.target.value)
        let checkList = val.target.value
        if (!this.quizCheckedVal.includes(checkList)) {
          this.quizCheckedVal.push(checkList)
          console.log(this.quizCheckedVal)
        } else {
          console.log("duplicate")
        }
      } else {
        console.log(event, val.target.value)
        let checkList = val.target.value
        if (this.quizCheckedVal.includes(checkList)) {
          let index = this.quizCheckedVal.indexOf(checkList)
          if (index > -1) {
            this.quizCheckedVal.splice(index, 1)
          }
          console.log(this.quizCheckedVal)
        } else {
          console.log("not found")
        }
      }
      if (this.quizCheckedVal.length === 0) {
        this.showform.zoom = false
        this.showform.dateTime = false
        this.showform.contact = false
      } else {
        this.showform.zoom = true
        this.showform.dateTime = true
        this.showform.contact = true
      }
    },
    checkAllAssignmentFunc(event, val) {
      this.checkAllAssignment = event
      if (event) {
        for (let assignment of this.assignmentList) {
          let checkList = (assignment.id + ":" + assignment.name).toString()
          if (!this.assignmentCheckedVal.includes(checkList)) {
            this.assignmentCheckedVal.push(checkList)
          }
        }
      } else {
        for (let assignment of this.assignmentList) {
          let checkList = (assignment.id + ":" + assignment.name).toString()
          if (this.assignmentCheckedVal.includes(checkList)) {
            let index = this.assignmentCheckedVal.indexOf(checkList)
            if (index > -1) {
              this.assignmentCheckedVal.splice(index, 1)
            }
          }
        }
      }
      if (this.assignmentCheckedVal.length === 0) {
        this.showform.zoom = false
        this.showform.dateTime = false
        this.showform.contact = false
      } else {
        this.showform.zoom = true
        this.showform.dateTime = true
        this.showform.contact = true
      }
    },
    checkSomeAssignment(event, val) {
      if (event) {
        let checkList = val.target.value
        if (!this.assignmentCheckedVal.includes(checkList)) {
          this.assignmentCheckedVal.push(checkList)
        }
      } else {
        let checkList = val.target.value
        if (this.assignmentCheckedVal.includes(checkList)) {
          let index = this.assignmentCheckedVal.indexOf(checkList)
          if (index > -1) {
            this.assignmentCheckedVal.splice(index, 1)
          }
        }
      }
      if (this.assignmentCheckedVal.length === 0) {
        this.showform.zoom = false
        this.showform.dateTime = false
        this.showform.contact = false
      } else {
        this.showform.zoom = true
        this.showform.dateTime = true
        this.showform.contact = true
      }
    },
    toTimestamp(strDate) {
      let datum = Date.parse(strDate)
      return datum / 1000
    },
    toTimestamp2(strDate) {
      let arr = strDate.split(/[- :]/),
        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
      let returnData = date.getTime() / 1000
      return returnData
    },
    toTimestamp3(strDate) {
      let arr = strDate.split(/[- :]/),
        date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4])
      let returnData = date.getTime() / 1000
      return returnData
    },
    dateValidator(val) {
      if (val !== undefined) {
        this.examDateTime.date = val
        this.bootstrapValidate.date = true
        return true
      }
      this.bootstrapValidate.date = false
      return false
    },
    startTimeValidator(val) {
      if (val !== undefined && val !== "") {
        let examStartTime = this.toTimestamp2(`1999-09-09 ${val}`)
        if (!isNaN(examStartTime)) {
          this.examDateTime.startTime = val
          this.bootstrapValidate.startTime = true
          let startTimeSplit = val.split(":")
          let hour = parseInt(startTimeSplit[0]) + 3
          if (hour > 24) {
            hour = hour - 24
          }
          if (this.examDateTime.endTime === "") {
            this.endTimeValidator(`${hour < 10 ? "0" : ""}${hour}:${startTimeSplit[1]}:${startTimeSplit[2] !== undefined ? startTimeSplit[2] : "00"}`)
          }
          return true
        }
      }
      this.bootstrapValidate.startTime = false
      return false
    },
    endTimeValidator(val) {
      if (val !== undefined && val !== "") {
        let examEndTime = this.toTimestamp2(`1999-09-09 ${val}`)
        if (!isNaN(examEndTime)) {
          this.examDateTime.endTime = val
          this.bootstrapValidate.endTime = true
          return true
        }
      }
      this.bootstrapValidate.endTime = false
      return false
    },
    startTimePickerHandle(val) {
      if (val["HH"] !== "" && val["mm"] !== "") {
        this.examDateTime.startTime = `${val["HH"]}:${val["mm"]}`
        let hourTime = parseInt(val["HH"])
        hourTime = hourTime + 3
        if (hourTime > 24) {
          hourTime = hourTime - 24
        }
        val["HH"] = hourTime > 9 ? hourTime.toString() : `0${hourTime.toString()}`
        if (this.examDateTime.endTime === "") {
          this.vModelForEndTime = val
        }
      } else {
        this.examDateTime.startTime = ``
      }
    },
    endTimePickerHandle(val) {
      if (val["HH"] !== "" && val["mm"] !== "") {
        this.examDateTime.endTime = `${val["HH"]}:${val["mm"]}`
      } else {
        this.examDateTime.endTime = ``
      }
    },
    emailInput(val) {
      if (val !== undefined) {
        this.contact.email = val
      }
    },
    phoneInput(val) {
      if (val !== undefined) {
        this.contact.phone = val
      }
    },
    otherInput(val) {
      if (val !== undefined) {
        this.contact.other = val
      }
    },
    useZoom() {
      this.showZoomInput = !this.showZoomInput
      this.eventZoomUrl = ""
      this.zoomIdConfigCMU = false
    },
    useLocation() {
      this.useStudentLocation = !this.useStudentLocation
    },
    async submitCreate() {
      this.alert1 = false
      this.alerttext = "คุณยังไม่ได้กรอก"
      if (!this.eventName) {
        this.alerttext = this.alerttext + " ชื่อEvent "
        this.alert1 = true
      }
      if (!this.eventLmsType) {
        this.alerttext = this.alerttext + " ประเภท service "
        this.alert1 = true
      }
      if (!this.eventLmsSelected) {
        this.alerttext = this.alerttext + " คอร์ส "
        this.alert1 = true
      }
      if (this.showZoomInput) {
        if (!this.eventZoomUrl) {
          this.alerttext = this.alerttext + " ZoomUrl "
          this.alert1 = true
        }
      }
      if (this.quizCheckedVal.length == 0 && this.assignmentCheckedVal.length == 0) {
        this.alerttext = this.alerttext + " Quiz/Assignment "
        this.alert1 = true
      }
      if (!this.examDateTime.date || !this.examDateTime.startTime || !this.examDateTime.endTime) {
        this.alerttext = this.alerttext + " วันและเวลาสอบ "
        this.alert1 = true
      }
      if (!this.alert1) {
        // Zoom id
        let zoomIdArr = this.eventZoomUrl.split("/")
        let zoomId = zoomIdArr[zoomIdArr.length - 1]
        // Quiz and Assignment
        let examAndAssignList = []
        for (let quiz of this.quizCheckedVal) {
          let quizSplitArr = quiz.split(":")
          let modeid = quizSplitArr.shift()
          let quizObj = {
            name: quizSplitArr.join(":"),
            mod: "quiz",
            modid: modeid,
          }
          examAndAssignList.push(quizObj)
        }
        for (let assignment of this.assignmentCheckedVal) {
          let assignmentSplitArr = assignment.split(":")
          let modeid = assignmentSplitArr.shift()
          let assignmentObj = {
            name: assignmentSplitArr.join(":"),
            mod: "assignment",
            modid: modeid,
          }
          examAndAssignList.push(assignmentObj)
        }
        let examLink = ""
        switch (this.eventLmsType) {
          case "cmuexam":
            examLink = `https://exam.cmu.ac.th/course/view.php?id=${this.eventLmsSelected}`
            break
          case "cmuonline":
            examLink = `https://elearning.cmu.ac.th/course/view.php?id=${this.eventLmsSelected}`
            break
          case "cmucanvas":
            examLink = `https://canvas.cmu.ac.th/courses/${this.eventLmsSelected}/quizzes`
            break
          case "cmumango":
            examLink = `https://mango.cmu.ac.th/courses/${this.eventLmsSelected}/quizzes`
            break
          default:
            break
        }

        let data = {
          owner: localStorage.getItem("email"),
          eventName: this.eventName,
          startAt: this.toTimestamp3(`${this.examDateTime.date} ${this.examDateTime.startTime}`),
          endAt: this.toTimestamp3(`${this.examDateTime.date} ${this.examDateTime.endTime}`),
          zoomMeeting: {
            enable: this.showZoomInput,
            meetingid: zoomId,
            meetingLink: this.eventZoomUrl,
          },
          srcExam: {
            service: this.eventLmsType,
            courseid: this.eventLmsSelected,
            groupsids: this.validateGroupIsAllCheck(this.studentGroups.listForSend),
            mod_exam_list: examAndAssignList,
          },
          contact: {
            email: this.contact.email,
            phone: this.contact.phone,
            other: this.contact.other,
          },
          examLink: examLink,
          location_enable: this.useStudentLocation,
        }
        await this.createEvent(data)
      }
    },
    async createEvent(jsonData) {
      let createEvent = ""
      if (jsonData) {
        this.createEventModal = false
        this.afterCreateEvent("open")
        createEvent = await apiv2.createEvent(jsonData)
        if (createEvent) {
          if (createEvent.status === true) {
            this.afterCreateEvent("success")
          } else {
            this.afterCreateEvent("error")
          }
        } else {
          this.afterCreateEvent("error")
        }
      }
      return createEvent
    },
    afterCreateEvent(command) {
      if (command === "open") {
        Swal.fire({
          title: "กำลังดำเนินการ",
          text: "ระบบกำลังนำเข้ารายชื่อนักศึกษาและเตรียมEventให้ท่าน กรุณาเปิดหน้านี้ทิ้งไว้",
          allowOutsideClick: false,
        })
        Swal.showLoading()
      } else if (command === "success") {
        Swal.fire({
          title: "เสร็จสิ้น", 
          html: "สร้างEventเรียบร้อย ท่านสามารถเข้าสู่Eventได้ทันที<br><b>ระบบอาจใช้เวลาสักครู่ในการประมวลผลและนำเข้านักศึกษาให้ท่าน</b>", 
          icon: "success",
          didDestroy: () => {
            this.$router.push({ query:{opencreate: false}})
          }
        })
        this.$emit("afterCreateEvent", true)
      } else if (command === "error") {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text:"การสร้าง event เกิดข้อผิดพลาด",
          icon:"error",
          didDestroy: () => {
            this.$router.push({ query:{opencreate: false}})
          }
        })
      }
    },
    validateGroupIsAllCheck(groupArr) {
      let isFound = false
      for (let i = 0; i < this.studentGroups.listForShow.length; i++) {
        isFound = false
        for (let j = 0; j < groupArr.length; j++) {
          if (this.studentGroups.listForShow[i].id.toString() === groupArr[j].toString()) {
            isFound = true
          }
        }
        if (isFound) {
          return groupArr
        }
      }
      return []
    },
    showUseStudentGroupDetail() {
      Swal.fire({
        icon: "question",
        text:
          "การเลือกกลุ่มนักศึกษาแบบเจาะจงใช้กรณีที่กระบวนวิชาออนไลน์มีการสร้างหรือแบ่ง Section ของนักศึกษาไว้เรียบร้อยแล้ว ซึ่งจะมีประโยชน์อย่างมากสำหรับกระบวนวิชาใหญ่ที่มีการสอบในแต่ละ Section ไม่พร้อมกัน",
        showConfirmButton: false,
      })
    },
  },
}
</script>
